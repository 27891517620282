import axiosInstance from "./axiosApi";
import LocalTokenService from "./localTokenService";
import {bindActionCreators} from "@reduxjs/toolkit";
import {refreshToken} from "../store/auth/authSlice";

const setup = (store) => {
    const { dispatch } = store;

    const bac = bindActionCreators({refreshToken}, dispatch);

    axiosInstance.interceptors.request.use(
        (config) => {
            const token = LocalTokenService.getLocalAccessToken();
            if (token) {
                config.headers["x-access-token"] = token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (originalConfig.url !== "/auth/signin" && err.response) {
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const rs = await axiosInstance.post("/auth/refreshtoken", {
                            refreshToken: LocalTokenService.getLocalRefreshToken(),
                        });
                        bac.refreshToken(rs.data);
                        LocalTokenService.setUser(rs.data);
                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(err);
        }
    );

};

export default setup;
