import React, {useState} from "react";
import {Spinner} from "@fluentui/react";

const defaultValues = Object.freeze({
    show:false,
    spinnerSize: 'medium',
    label: null,
    ariaLive:'polite',
    labelPosition:'bottom',
    styles: null,
    className: null,
    showSpinner: () => {}
});


export const SpinnerContext = React.createContext(defaultValues);

export const SpinnerProvider = ({ children }) => {

    const [show, setShow] = useState(defaultValues);

    const showSpinner = () => setShow(defaultValues);

    const spinnerValue = { ...show, showSpinner };

    return (
        <SpinnerContext.Provider value={spinnerValue}>
            {
                spinnerValue?.show &&
                <Spinner {...spinnerValue} />
            }
            {children}
        </SpinnerContext.Provider>
    );
}

export const useSpinnerProvider = () => {
    return React.useContext(SpinnerContext);
}
