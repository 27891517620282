import { createSlice } from "@reduxjs/toolkit";
import LocalTokenService from "../../services/localTokenService";

const user = LocalTokenService.getUser();

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loginPending: (state) => {
            state.isLoading = true;
        },
        loginSuccess: (state,{ payload }) => {
            state.isLoading = false;
            state.isAuth = true;
            state.error = "";
            state.user = payload;
        },
        loginFail: (state, { payload }) => {
            state.isLoading = false;
            state.error = payload;
            state.user = null;
        },
        refreshToken:(state, { payload })=>{
            state.user = payload;
        }
    },
});

export const { loginPending, loginSuccess, refreshToken, loginFail } = authSlice.actions;

export default authSlice.reducer;
