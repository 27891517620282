import * as yup from "yup";

export const signupUserSchema = yup.object().shape({
    firstName: yup.string().required().label('First Name'),
    lastName: yup.string().required().label('Last Name'),
    email: yup.string().email().required().label('Email'),
    userName: yup.string().required().label('Username'),
    password: yup.string().min(4).required().label('Password'),
    confirmPassword: yup.string().required().label('Confirm Password').oneOf([yup.ref("password"),null]),
    phoneNumber: yup.string().required().label('Phone Number'),
});
