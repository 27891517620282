import React from 'react';
import {Stack} from "@fluentui/react";

export const LogoTitle = () => {
    return (
        <Stack horizontal className="app-title">
            <span>Upgolf</span>
            <span>PRO</span>
        </Stack>
    );
};