import React from "react";
import { ThemeProvider, CommandBarButton } from "@fluentui/react";
import { useLocalStorage } from "react-use";
import {themes} from "./appTheme";

export const ThemeContext = React.createContext({
  theme: "dark",
  changeTheme: (name) => { },
});

export const useTheme = () => React.useContext(ThemeContext);

const ThemeConsumer = ({ children }) => {
  const { theme } = useTheme();
  return <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>;
}

export const DynamicThemeProvider = ({ children }) => {
  const [theme, setTheme] = useLocalStorage("theme", "default");

  const changeTheme = (name) => themes[name] && setTheme(name);
  const themeContextValue = { theme, changeTheme };

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <ThemeConsumer>{children}</ThemeConsumer>
    </ThemeContext.Provider>
  );
}

export const ThemeToggle = ({ as: ButtonComponent }) => {
  const { theme, changeTheme } = useTheme();
  const menuItems = Object.keys(themes).map((key) => ({
    key,
    text: key,
    canCheck: true,
    checked: theme === key,
    onClick: () => changeTheme(key),
  }));

  const styles = props => ({
    borderRadius: props.theme.spacing.l1
  });

  return (
    <ButtonComponent styles={styles}
      menuProps={{ shouldFocusOnMount: true, items: menuItems }}
      iconProps={{ iconName: "Color" }}>
      {theme}
    </ButtonComponent>
  );
}

ThemeToggle.defaultProps = {
  as: CommandBarButton,
};
