import axios from "axios";
import axiosInstance from "./axiosApi";
import LocalTokenService from "./localTokenService";
import {loginSuccess} from "../store/auth/authSlice";
import {GLOBAL_CONFIG} from "../global/constants";


export const {REACT_APP_BASE_API_URL} = GLOBAL_CONFIG;

const loginUrl = `/tokens`;
const userSignupUrl = `/users/self-register`;
const userProfileUrl = `/user`;
const logoutUrl = `/user/logout`;
const newAccessJWT = `/tokens`;
const userVerificationUrl = `/verify`;

export const userSignup = async (frmData, dispatch) => {
    let error = null;
    try {
        const { data, status } = await axiosInstance.post(userSignupUrl, frmData);
        return { isSuccess:true, data};
    } catch (ex) {
        error = ex.response?.data?.errors
    }
    return { isSuccess:false, error };
};

export const userLogin = async (frmData, dispatch) => {
    let error = null;
    try {
        const { data, status } = await axiosInstance.post(loginUrl, frmData);
        //if(status === 200)
        LocalTokenService.setUser(data);
        dispatch && dispatch(loginSuccess(data))
        return { isSuccess:true, data };
    } catch (ex) {
        error = ex;
    }
    return { isSuccess:false, error };
};

export const userRegistrationVerification = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axiosInstance.patch(userVerificationUrl, frmData);

            resolve(res.data);
            if (res.data.status === "success") {
                resolve(res.data);
            }
        } catch (error) {
            reject({ status: "error", message: error.error });
        }
    });
};

export const fetchUser = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const accessJWT = sessionStorage.getItem("accessJWT");

            if (!accessJWT) {
                reject("Token not found!");
            }

            const res = await axios.get(userProfileUrl, {
                headers: {
                    Authorization: accessJWT,
                },
            });

            resolve(res.data);
        } catch (error) {
            console.log(error);
            reject(error.message);
        }
    });
};

export const fetchNewAccessJWT = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const { refreshJWT } = JSON.parse(localStorage.getItem("crmSite"));

            if (!refreshJWT) {
                reject("Token not found!");
            }

            const res = await axios.get(newAccessJWT, {
                headers: {
                    Authorization: refreshJWT,
                },
            });

            if (res.data.status === "success") {
                sessionStorage.setItem("accessJWT", res.data.accessJWT);
            }

            resolve(true);
        } catch (error) {
            if (error.message === "Request failed with status code 403") {
                localStorage.removeItem("crmSite");
            }

            reject(false);
        }
    });
};

export const userLogout = async () => {
    try {
        await axios.delete(logoutUrl, {
            headers: {
                Authorization: sessionStorage.getItem("accessJWT"),
            },
        });
    } catch (error) {
        console.log(error);
    }
};
