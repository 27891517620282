import React, {useEffect, useState} from 'react';
import standings from './player_standings.json';
import ScheduleService from 'services/scheduleService';
import { getFormattedTeeTime, getSimpleDate, incrementStartTime, IsNotUndefinedOrNotNull } from 'utilities/utils';

import {
  DetailsList,
  ConstrainMode,
  DetailsListLayoutMode,
  SelectionMode,
  mergeStyleSets,
  TooltipHost,
  CheckboxVisibility,
  Dropdown,
  DropdownMenuItemType
} from '@fluentui/react';

const columns = [
  {
    key: 'Name',
    name: 'Team ID',
    fieldName: 'Name',
    minWidth: 60,
    maxWidth: 60,
  },
  {
    key: 'PlayerName',
    name: 'Player Name',
    fieldName: 'PlayerName',
    minWidth: 100,
    maxWidth: 150,
  },
  {
    key: 'HandicapAtRound',
    name: 'HCP At Round',
    fieldName: 'HandicapAtRound',
    minWidth: 50,
    maxWidth: 100,
  },
  {
    key: 'TotalPoints',
    name: 'Total Points',
    fieldName: 'TotalPoints',
    minWidth: 50,
    maxWidth: 100,
  }
];

const gridStyles = {
  root: {
    selectors: {
      '& [role=grid]': {
        height: '100vh',
      },
    },
  },
};

const dropdownStyles = {
  dropdown: {
    width: 200,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
};

const classNames = mergeStyleSets({
  header: {
    margin: '.5em 0',
  },
});

const setupSelectionDropdown = () => {
  let settings = ScheduleService.getLeagueSettings();
  let options = [];
  for (let w=0; w < settings.totalWeeks; w++) {
    let wknum = w + 1;
    if (w === 0) {
      options.push({ key: 'firstHalfHeaader', text: 'First Half', itemType: DropdownMenuItemType.Header });
      let text = 'Week ' +wknum;
      options.push({ key: w, text: text });
    // } else if (w === 1) {
    //   let text = 'Week ' + wknum + ' (CANCELLED)';
    //   options.push({ key: w, text: text });
    } else if (w === 9) {
      let text = 'Week ' + wknum + ' (Knock-Downs)';
      options.push({ key: w, text: text });
      options.push({ key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider });
      options.push({ key: 'secondHalfHeaader', text: 'Second Half', itemType: DropdownMenuItemType.Header });
    } else if (w === 15) {
      let text = 'Week ' + wknum + ' (CANCELLED)';
      options.push({ key: w, text: text });
    }  else if (w === 19) {
      let text = 'Week ' + wknum + ' (Knock-Downs)';
      options.push({ key: w, text: text });
    } else {
      let text = 'Week ' +wknum;
      options.push({ key: w, text: text });
    }
  }
  return options;
}

function Standings(props) {
  const [weekly, setWeekly]  = useState([]);
  const [selectedKey, setSelectedKey]  = useState(18);
  const [options, setOptions]  = useState([]);

  useEffect(()=>{
    const options = setupSelectionDropdown();
    setOptions(options);
  },[]);

  useEffect(()=>{
    if(IsNotUndefinedOrNotNull(standings)) {
      setupWeeklyResults(selectedKey, standings);
    }
  },[selectedKey])

  const onWeekSelectionChange = (event, option, index)=> {
    setSelectedKey(option.key);
    setWeekly([]);
  }

  const setupWeeklyResults = (weekNum, standings) => {
    const results = [];
    
    setWeekly(results);

    if (weekNum === 0) {
      for (var i = 0; i < standings.WK1.length; i++) {
        results.push(standings.WK1[i]);
      }
    } else if (weekNum === 1) {
      for (var i = 0; i < standings.WK2.length; i++) {
        results.push(standings.WK2[i]);
      }
    } else if (weekNum === 2) {
      for (var i = 0; i < standings.WK3.length; i++) {
        results.push(standings.WK3[i]);
      }
    } else if (weekNum === 3) {
      for (var i = 0; i < standings.WK4.length; i++) {
        results.push(standings.WK4[i]);
      }
    } else if (weekNum === 4) {
      for (var i = 0; i < standings.WK5.length; i++) {
        results.push(standings.WK5[i]);
      }
    } else if (weekNum === 5) {
      for (var i = 0; i < standings.WK6.length; i++) {
        results.push(standings.WK6[i]);
      }
    } else if (weekNum === 6) {
      for (var i = 0; i < standings.WK7.length; i++) {
        results.push(standings.WK7[i]);
      }
    } else if (weekNum === 7) {
      for (var i = 0; i < standings.WK8.length; i++) {
        results.push(standings.WK8[i]);
      }
    } else if (weekNum === 8) {
      for (var i = 0; i < standings.WK9.length; i++) {
        results.push(standings.WK9[i]);
      }
    } else if (weekNum === 9) {
      for (var i = 0; i < standings.WK10.length; i++) {
        results.push(standings.WK10[i]);
      }
    } else if (weekNum === 10) {
      for (var i = 0; i < standings.WK11.length; i++) {
        results.push(standings.WK11[i]);
      }
    } else if (weekNum === 11) {
      for (var i = 0; i < standings.WK12.length; i++) {
        results.push(standings.WK12[i]);
      }
    } else if (weekNum === 12) {
      for (var i = 0; i < standings.WK13.length; i++) {
        results.push(standings.WK13[i]);
      }
    } else if (weekNum === 13) {
      for (var i = 0; i < standings.WK14.length; i++) {
        results.push(standings.WK14[i]);
      }
    } else if (weekNum === 14) {
      for (var i = 0; i < standings.WK15.length; i++) {
        results.push(standings.WK15[i]);
      }
    } else if (weekNum === 15) {
      for (var i = 0; i < standings.WK16.length; i++) {
        results.push(standings.WK16[i]);
      }
    } else if (weekNum === 16) {
      for (var i = 0; i < standings.WK17.length; i++) {
        results.push(standings.WK17[i]);
      }
    } else if (weekNum === 17) {
      for (var i = 0; i < standings.WK18.length; i++) {
        results.push(standings.WK18[i]);
      }
    } else if (weekNum === 18) {
      for (var i = 0; i < standings.WK19.length; i++) {
        results.push(standings.WK19[i]);
      }
    } else if (weekNum === 19) {
      for (var i = 0; i < standings.WK20.length; i++) {
        results.push(standings.WK20[i]);
      }
    }
    
    setWeekly(results);
    // console.log('Current week standings: ', results);
  }

  const onRenderDetailsHeader = (props, defaultRender) => {
    if (!props) {
      return null;
    }
    const onRenderColumnHeaderTooltip = tooltipHostProps => (
      <TooltipHost {...tooltipHostProps} />
    );

    return defaultRender({
      ...props,
      onRenderColumnHeaderTooltip,
    });
  };

  return (
    <div style={{overflow:"auto"}}>
      <h2 className={classNames.header}>Standings (Official Results)</h2>
      <span>Note: Scoring is based on handicaps last known/calculated from last season. Final scoring to be updated and applied. These results are currently unofficial.</span>
      <div style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
        <Dropdown
            placeholder="Select a week"
            label="Standings after (Select a week)"
            options={options}
            styles={dropdownStyles}
            defaultSelectedKey={selectedKey}
            onChange={(e,o,i)=>onWeekSelectionChange(e,o,i)}
        />
      </div>
      <DetailsList
        styles={gridStyles}
        items={weekly.slice(0, 100)}
        columns={columns}
        checkboxVisibility={CheckboxVisibility.always}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        constrainMode={ConstrainMode.unconstrained}
        selectionMode={SelectionMode.none}
        onRenderDetailsHeader={onRenderDetailsHeader}
        selectionPreservedOnEmptyClick
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
      />{' '}
    </div>
  );
}

export default Standings;