import React, {useEffect, useState} from 'react';
import { useNavigate, Navigate, Routes, Route } from 'react-router-dom';
import ScheduleService from 'services/scheduleService';
import roster from 'pages/Roster/player_roster.json';
import { getFormattedTeeTime, getSimpleDate, incrementStartTime, IsNotUndefinedOrNotNull } from 'utilities/utils';
import {
  DetailsList,
  ConstrainMode,
  DetailsListLayoutMode,
  SelectionMode,
  mergeStyleSets,
  TooltipHost,
  CheckboxVisibility,
  IStyleSet,
  Label,
  ILabelStyles,
  Pivot,
  PivotItem,
  Dropdown,
  DropdownMenuItemType
} from '@fluentui/react';
import {useWindowSize} from "react-use";

const ROSTER = "roster";

const allWkColumns = [
  {
    key: 'teeTime',
    name: 'Tee Time',
    fieldName: 'teeTime',
    minWidth: 40,
    maxWidth: 40,
  },
  {
    key: 'opponentAFullName',
    name: 'Opponent A (HCP)',
    fieldName: 'oppAFullName',
    minWidth: 130,
    maxWidth: 130,
  },
  {
    key: 'oppAid',
    name: 'ID',
    fieldName: 'oppAid',
    minWidth: 30,
    maxWidth: 30,
    screen: 500
  },
  {
    key: 'phone',
    name: 'Phone',
    fieldName: 'oppAphone',
    minWidth: 75,
    maxWidth: 75
  },
  {
    key: 'opponentBFullName',
    name: 'Opponent B (HCP)',
    fieldName: 'oppBFullName',
    minWidth: 130,
    maxWidth: 130,
  },
  {
    key: 'oppBid',
    name: 'ID',
    fieldName: 'oppBid',
    minWidth: 30,
    maxWidth: 30,
    screen: 500
  },
  {
    key: 'oppBphone',
    name: 'Phone',
    fieldName: 'oppBphone',
    minWidth: 75,
    maxWidth: 75,
  }
];

const seasonColumns = [
  {
    key: 'weekNum',
    name: 'Wk',
    fieldName: 'weekNum',
    minWidth: 25,
    maxWidth: 25,
  },
  {
    key: 'date',
    name: 'Date',
    fieldName: 'date',
    minWidth: 45,
    maxWidth: 45,
  },
  {
    key: 'match0',
    name: 'Match 1',
    fieldName: 'match-0',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match1',
    name: 'Match 2',
    fieldName: 'match-1',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match2',
    name: 'Match 3',
    fieldName: 'match-2',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match3',
    name: 'Match 4',
    fieldName: 'match-3',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match4',
    name: 'Match 5',
    fieldName: 'match-4',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match5',
    name: 'Match 6',
    fieldName: 'match-5',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match6',
    name: 'Match 7',
    fieldName: 'match-6',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match7',
    name: 'Match 8',
    fieldName: 'match-7',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match8',
    name: 'Match 9',
    fieldName: 'match-8',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match9',
    name: 'Match 10',
    fieldName: 'match-9',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match10',
    name: 'Match 11',
    fieldName: 'match-10',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match11',
    name: 'Match 12',
    fieldName: 'match-11',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match12',
    name: 'Match 13',
    fieldName: 'match-12',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match13',
    name: 'Match 14',
    fieldName: 'match-13',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match14',
    name: 'Match 15',
    fieldName: 'match-14',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  },
  {
    key: 'match15',
    name: 'Match 16',
    fieldName: 'match-15',
    isMultiline: true,
    minWidth: 75,
    maxWidth: 75,
  }
  // {
  //   key: 'match16',
  //   name: 'Match 17',
  //   fieldName: 'match-16',
  //   isMultiline: true,
  //   minWidth: 75,
  //   maxWidth: 75,
  // },
  // {
  //   key: 'match17',
  //   name: 'Match 18',
  //   fieldName: 'match-17',
  //   isMultiline: true,
  //   minWidth: 75,
  //   maxWidth: 75,
  // }
]

const gridStyles = {
  root: {
    selectors: {
      '& [role=grid]': {
        height: '100vh',
      },
    },
  },
};

const labelStyles = {
  root: { marginTop: 10 },
};

const dropdownStyles = {
  dropdown: {
    width: 200,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
};

const classNames = mergeStyleSets({
  header: { margin: '.5em 0' }
});

const setupSelectionDropdown = () => {
  let settings = ScheduleService.getLeagueSettings();
  let options = [];
  for (let w=0; w < settings.totalWeeks; w++) {
    let wknum = w + 1;
    if (w === 0) {
      options.push({ key: 'firstHalfHeaader', text: 'First Half', itemType: DropdownMenuItemType.Header });
      let text = 'Week ' +wknum;
      options.push({ key: w, text: text });
    // } else if (w === 1 || w === 10) {
    //   let text = 'Week ' + wknum + ' (CANCELLED)';
    //   options.push({ key: w, text: text });
    } else if (w === 20) {
      let text = 'Week ' + wknum + ' (Knock-Downs)';
      options.push({ key: w, text: text });
      options.push({ key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider });
      options.push({ key: 'secondHalfHeaader', text: 'Second Half', itemType: DropdownMenuItemType.Header });
    } else if (w === 19) {
      let text = 'Week ' + wknum + ' (Knock-Downs)';
      options.push({ key: w, text: text });
    } else {
      let text = 'Week ' +wknum;
      options.push({ key: w, text: text });
    }
  }
  return options;
}

const currentWeekNum = 19;

const getRoster = () => {
  return roster;  // JSON.parse(localStorage.getItem(ROSTER));
};

const Schedule = (props)=> {
  const [season, setSeason]  = useState([]);
  const [weekly, setWeekly]  = useState([]);
  const [wkColumns, setWkColumns]  = useState([]);
  const [selectedKey, setSelectedKey]  = useState(currentWeekNum);
  const [options, setOptions]  = useState([]);
  const { width } = useWindowSize();

  let navigate = useNavigate();

  useEffect(()=>{
    const columns = allWkColumns.filter(i=> i.screen === undefined || width >= i.screen);
    setWkColumns(columns);
  },[width]);

  useEffect(()=>{
    const options = setupSelectionDropdown();
    setOptions(options);
  },[]);

  useEffect(()=>{
    const roster = getRoster();
    setupFullSchedule();
    setupWeeklySchedule(selectedKey);
  },[selectedKey])

  const onWeekSelectionChange = (event, option, index)=> {
    setSelectedKey(option.key);
    setWeekly([]);
  }

  const setupWeeklySchedule = (crrtWk) => {
    let weekNum = crrtWk + 1;
    const schItems = [];
    let currentWeek = ScheduleService.getScheduleByWeekNum(weekNum);
    console.log('Current week schedule: ', currentWeek);

    let dt = new Date();
    dt.setHours(16,23,0,0);
    let teeTime = getFormattedTeeTime(dt);
    let matchup = {};

    if (weekNum === 16) { //|| weekNum === 11) {
      matchup = {
        oppAid: '', oppAFullName: 'Week Cancelled', oppAphone: 'Cancelled',
        oppBid: '', oppBFullName: 'Week Cancelled', oppBphone: 'Cancelled',
        teeTime: 'n/a'
      };
      schItems.push(matchup);
    } else if (weekNum === 21 || weekNum === 22) {
    // if (weekNum === 21 || weekNum === 20) {
      matchup = {
        oppAid: '', oppAFullName: 'Schedule TBD', oppAphone: 'Knock-Downs',
        oppBid: '', oppBFullName: 'Schedule TBD', oppBphone: 'Knock-Downs',
        teeTime: 'n/a'
      };
      schItems.push(matchup);
    // } else if (weekNum === 18 || weekNum === 19) {
    //   matchup = {
    //     oppAid: '', oppAFullName: 'Schedule TBD',
    //     oppBid: '', oppBFullName: 'Schedule TBD',
    //     teeTime: 'n/a'
    //   };
    //   schItems.push(matchup);
    } else {
      for(let i=0; i < currentWeek.length; i++) {
        if (i % 2 === 0) {
          // dt = incrementStartTime(dt);
          teeTime = getFormattedTeeTime(currentWeek[i].teeTime);
        }
        let matchup = {};
        if (weekNum -1 === currentWeekNum) {
          matchup = {
            oppAid: currentWeek[i].teamA.name, oppAFullName: currentWeek[i].teamA.players[0].fullName + ' (' + currentWeek[i].teamA.players[0].playerDetails.currentHandicap + ')', oppAphone: currentWeek[i].teamA.players[0].playerDetails.phone,
            oppBid: currentWeek[i].teamB.name, oppBFullName: currentWeek[i].teamB.players[0].fullName + ' (' + currentWeek[i].teamB.players[0].playerDetails.currentHandicap + ')', oppBphone: currentWeek[i].teamB.players[0].playerDetails.phone,
            teeTime: teeTime
          };
        } else {
          matchup = {
            oppAid: currentWeek[i].teamA.name, oppAFullName: currentWeek[i].teamA.players[0].fullName, oppAphone: currentWeek[i].teamA.players[0].playerDetails.phone,
            oppBid: currentWeek[i].teamB.name, oppBFullName: currentWeek[i].teamB.players[0].fullName, oppBphone: currentWeek[i].teamB.players[0].playerDetails.phone,
            teeTime: teeTime
          };
        }
        schItems.push(matchup);
      }
    }
    setWeekly(schItems);
  }

  const setupFullSchedule = () => {
    let totalRounds = ScheduleService.getTotalRounds();
    const schWeeks = [];
    let dt = new Date("04/23/2023");

    for(let r=1; r <= totalRounds; r++) {
      let matches = ScheduleService.getScheduleByWeekNum(r);
      let week = { weekNum: r };
      
      if(matches !== null && matches !== undefined && matches.length > 0) {
        week = { weekNum: r, date: getSimpleDate(matches[0].teeTime)};
        for(let m=0; m < matches.length; m++) {
          week = {...week, [`match-${m}`]: matches[m].teamA.players[0].familyName + ', ' + matches[m].teamA.players[0].givenName.charAt(0) + '. (' + matches[m].teamA.name + ')    versus   ' + matches[m].teamB.players[0].familyName + ', ' + matches[m].teamB.players[0].givenName.charAt(0) + ' (' + matches[m].teamB.name + ')'};
        }
      } else if ((matches === null || matches === undefined || matches.length === 0) && (r === 21 | r === 22)) {
        let dt = r === 10 ? new Date("06/25/2024") : new Date("09/03/2024");
        week = { weekNum: r, date: getSimpleDate(dt)};
        week = {...week, [`match-${0}`]: 'WEEK ' + r };
        week = {...week, [`match-${1}`]: 'KNOCK' };
        week = {...week, [`match-${2}`]: 'DOWNS' };
      }
      else if ((matches === null || matches === undefined || matches.length === 0) && (r === 18 | r === 19)) {
        let dt = r === 18 ? new Date("08/20/2024") : new Date("08/27/2024");
        week = { weekNum: r, date: getSimpleDate(dt)};
        week = {...week, [`match-${0}`]: 'WEEK ' + r };
        week = {...week, [`match-${1}`]: 'SCHEDULE' };
        week = {...week, [`match-${2}`]: 'TBD' };
      }
      schWeeks.push(week);       
    }
    setSeason(schWeeks);
  }

  const onRenderDetailsHeader = (props, defaultRender) => {
    if (!props)  return null;

    const onRenderColumnHeaderTooltip = tooltipHostProps => <TooltipHost {...tooltipHostProps} />;

    return defaultRender({
      ...props,
      onRenderColumnHeaderTooltip,
    });
  };

  return (
      <div style={{overflow:"auto"}}>
        {/* <h1 className={classNames.header}>2022 Schedule</h1> */}
        <Pivot aria-label="Basic Pivot Example">
          <PivotItem headerText="Weekly Schedule">
            {/* <Label styles={labelStyles}>Weekly Schedule</Label> */}
            <div style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
              <Dropdown
                  placeholder="Select a week"
                  label="Select a week"
                  options={options}
                  styles={dropdownStyles}
                  defaultSelectedKey={selectedKey}
                  onChange={(e,o,i)=>onWeekSelectionChange(e,o,i)}
              />
            </div>
            <DetailsList
                styles={gridStyles}
                items={weekly.slice(0, 100)}
                columns={wkColumns}
                checkboxVisibility={CheckboxVisibility.always}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                constrainMode={ConstrainMode.unconstrained}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={onRenderDetailsHeader}
                selectionPreservedOnEmptyClick
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            />{' '}
          </PivotItem>
          <PivotItem headerText="Full Schedule">
            <DetailsList
                  styles={gridStyles}
                  items={season}
                  columns={seasonColumns}

                  checkboxVisibility={CheckboxVisibility.always}
                  layoutMode={DetailsListLayoutMode.fixedColumns}
                  constrainMode={ConstrainMode.unconstrained}
                  selectionMode={SelectionMode.multiple}
                  onRenderDetailsHeader={onRenderDetailsHeader}
                  selectionPreservedOnEmptyClick
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              />{' '}
          </PivotItem>
          {/* <PivotItem headerText="Option #3">
          <Label styles={labelStyles}>Option #3</Label>
        </PivotItem> */}
        </Pivot>
      </div>
  );
}

export default Schedule;
