import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';

export const IsArrayWithLength = (items) =>  Array.isArray(items) && items.length > 0;

export const IsNotEmpty = (value) => (typeof value !== 'undefined' && typeof value === 'string' && value.trim().length > 0);

export const IsEmptyObject = (object) =>  !(object.constructor === Object && Object.keys(object).length >0);

export const IsNotUndefinedOrNotNull = (value) => (typeof value !== 'undefined' && value != null);

export const IsFunction = (f) => f !== undefined && f !== null && typeof f === "function" ;

export const IsNumberGreaterThanZero = (value) => (typeof value !== 'undefined' && typeof value == 'number' && value > 0 );

export const GetRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

export const getTimeFromNow = (datetime) => {
    if(dayjs.Dayjs === undefined){
      dayjs.extend(relativeTime);
    }
    return dayjs(datetime).fromNow();
  };

export const getFormattedTeeTime = (datetime) => {
    if(dayjs.Dayjs === undefined) {
        dayjs.extend(relativeTime);
    }
    return dayjs(datetime).format("hh:mm");
};

export const getSimpleDate = (datetime) => {
    if(dayjs.Dayjs === undefined) {
        dayjs.extend(relativeTime);
    }
    return dayjs(datetime).format("MMM-DD");
};

export const incrementStartTime = (datetime) => {
    let inc = datetime.getMinutes() + 7;
    datetime.setMinutes(inc);
    return datetime;
};