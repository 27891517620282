import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./user/userSlice";
import postSlice from "./post/postSlice";
import commonSlice from "./common/commonUi";
import authSlice from "./auth/authSlice";

export default configureStore({
    reducer: {
        auth: authSlice,
        user: userSlice,
        post: postSlice,
        common: commonSlice
    },
});


