import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {GLOBAL_CONFIG} from "../../global/constants";

export const {REACT_APP_BASE_API_URL} = GLOBAL_CONFIG;


export const GetPosts = createAsyncThunk(
    "post/getPosts",
    async (dispatch, getState) => await (await axios.get(`${REACT_APP_BASE_API_URL}/posts`)).data
);

export const CreatePost = createAsyncThunk(
    "post/createPost",
    async (post) => await (await axios.post(`${REACT_APP_BASE_API_URL}/post`, post)).data
);

export const DeletePost = createAsyncThunk(
    "post/deletePost",
    async (post) => await (await axios.post(`${REACT_APP_BASE_API_URL}/post`, post)).data
);
