
const USER = "user";

const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem(USER));
    return user?.refreshToken;
};

const getLocalAccessToken = () => {
    const user = JSON.parse(localStorage.getItem(USER));
    return user?.accessToken;
};

const updateLocalAccessToken = (token) => {
    let user = JSON.parse(localStorage.getItem(USER));
    user.accessToken = token;
    localStorage.setItem(USER, JSON.stringify(user));
};

const getUser = () => {
    return JSON.parse(localStorage.getItem(USER));
};

const setUser = (user) => {
    localStorage.setItem(USER, JSON.stringify(user));
};

const removeUser = () => {
    localStorage.removeItem(USER);
};

const LocalTokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
};

export default LocalTokenService;
