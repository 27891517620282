import axios from "axios";
import {GLOBAL_CONFIG} from "../global/constants";


export const {REACT_APP_BASE_API_URL} = GLOBAL_CONFIG;


const axiosInstance = axios.create({
    baseURL: `${REACT_APP_BASE_API_URL}`,
    headers: {
        "Content-Type": "application/json",
        "tenant":"root"
    },
});

export default axiosInstance;
