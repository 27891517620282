
import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
    name: "common",
    initialState: {
        ui:{
            collapseNav: localStorage.getItem("NavToggler.isNavCollapsed") === "true" || window.innerWidth < 720
        },
        loading: false,
        error: null,
    },
    reducers: {
        navToggle: (state, action) => {
            console.log({state, action});
            const ui = state.ui;
            localStorage.setItem("NavToggler.isNavCollapsed", !ui.collapseNav ? "true" : "false")
            ui.collapseNav = !ui.collapseNav;
        }
    }
});

export const { navToggle } = commonSlice.actions;

export default commonSlice.reducer;