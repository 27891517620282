import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-white shadow py-3">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="text-sm-start text-center">
                            <p className="mb-0 text-muted">© 2022 UpGolf.Pro. Design with
                                 <i className="mdi mdi-emoticon-poop text-danger"/> by
                                <a href="https://www.cadensoft.com/" target="_blank" className="text-reset"> cadensoft</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;