import React from 'react';
import roster from './player_roster.json';
import ScheduleService from 'services/scheduleService';
import {
  DetailsList,
  ConstrainMode,
  DetailsListLayoutMode,
  SelectionMode,
  mergeStyleSets,
  TooltipHost,
  CheckboxVisibility,
} from '@fluentui/react';

const ROSTER = "roster";
const columns = [
  {
    name: 'Team',
    fieldName: 'teamName',
    minWidth: 50,
    maxWidth: 50,
    screen: 500
  },
  {
    key: 'familyName',
    name: 'Last Name',
    fieldName: 'familyName',
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: 'givenName',
    name: 'First Name',
    fieldName: 'givenName',
    minWidth: 70,
    maxWidth: 70,
  },
  {
    key: 'currentHandicap',
    name: 'HCP',
    fieldName: 'currentHandicap',
    minWidth: 50,
    maxWidth: 50,
  },
  {
    key: 'phone',
    name: 'Phone',
    fieldName: 'phone',
    minWidth: 80,
    maxWidth: 80,
    isResizable: true,
  },
  {
    key: 'email',
    name: 'Email',
    fieldName: 'email',
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
  }
];

const gridStyles = {
  root: {
    selectors: {
      '& [role=grid]': {
        height: '100vh',
      },
    },
  },
};

const classNames = mergeStyleSets({
  header: {
    margin: '.5em 0',
  },
});

function Roster(props) {
  // ScheduleService.createScheduleFromPlayerRoster(roster);
  // localStorage.setItem(ROSTER, JSON.stringify(roster));

  const onRenderDetailsHeader = (props, defaultRender) => {
    if (!props) {
      return null;
    }
    const onRenderColumnHeaderTooltip = tooltipHostProps => (
      <TooltipHost {...tooltipHostProps} />
    );

    return defaultRender({
      ...props,
      onRenderColumnHeaderTooltip,
    });
  };

  return (
    <div style={{overflow:"auto"}}>
      <h2 className={classNames.header}>Player Roster</h2>
      <DetailsList
        styles={gridStyles}
        items={roster.slice(0, 100)}
        columns={columns}
        checkboxVisibility={CheckboxVisibility.always}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        constrainMode={ConstrainMode.unconstrained}
        selectionMode={SelectionMode.multiple}
        onRenderDetailsHeader={onRenderDetailsHeader}
        selectionPreservedOnEmptyClick
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
      />{' '}
    </div>
  );
}

export default Roster;
