import React from "react";
import ReactDOM from "react-dom"
import "./index.css";
import "./styles/app.scss"
import App from "./App";
import store from "./store/appStore";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { DynamicThemeProvider } from "./global/themes";
import {ModalProvider} from "./components/Modal";
import {SpinnerProvider} from "./components/Spinner/SpinnerProvider";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import setupInterceptors from "./services/setupInterceptors";

initializeIcons();

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <SpinnerProvider>
              <ModalProvider>
                  <DynamicThemeProvider>
                      <App />
                  </DynamicThemeProvider>
              </ModalProvider>
          </SpinnerProvider>
      </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
setupInterceptors(store);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
