import React, {useEffect, useRef} from 'react';
import { Stack, styled, classNamesFunction } from '@fluentui/react';
import TopHeader from "../TopHeader";
import Footer from "../Footer";
import { Outlet } from "react-router-dom";
import SideBar from "../SideBar";
import {useDispatch, useSelector} from "react-redux";

const getStyles = ({ theme }) => {
    return {
        root: {
            backgroundColor: theme.palette.neutralLight,
            boxShadow: theme.effects.elevation8,
            height:"100vh"
        },
        // sidebar: {

        // },
        rootWrapper:{
            margin: theme.spacing.l1,
            boxShadow:  theme.effects.elevation4,
            backgroundColor: theme.semanticColors.bodyBackground,
            position: "relative",
        },
        content:{
            position: "absolute",
            left:"0",
            top:"0",
            right:"0"
        },
        mainStack:{
            padding: theme.spacing.l1,
            overflow:"hidden"
        }
    };
};

const getClassNames = classNamesFunction();

const MasterLayoutComponent = ({ children, theme, styles }) => {
  const classNames = getClassNames(styles, { theme });

    const dispatch = useDispatch()
    const { ui: { collapseNav }} = useSelector((state) => state.common);

  return (
    //   <div className={`page-wrapper landrick-theme ${ !collapseNav && "toggled"}`} >
      <div className={`page-wrapper landrick-theme`} >
          {/* <p>MasterLayoutComponent</p>
          <SideBar /> */}
          <main className="page-content bg-light">
              <TopHeader />
              <div className="container-fluid">
                  <div className="layout-specing">
                     <Outlet/>
                  </div>
              </div>
              <Footer />
          </main>
      </div>
  );
}

export const MasterLayout = styled(MasterLayoutComponent, getStyles);
