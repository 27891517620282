
import { set } from "react-hook-form";
import { IsNotUndefinedOrNotNull, IsArrayWithLength, GetRandomInt } from "utilities/utils";
import { array } from "yup";
import schedule from './2024_schedule.json';

const SCHEDULE_VERSION = "schedule_version";
const SCHEDULE = "schedule";
const LeagueSettings = {
    startDate: new Date("4-26-22"),
    totalWeeks: 20
};

const getLeagueSettings = () => {
    return LeagueSettings;
};

const createScheduleFromPlayerRoster = (roster) => {
    let schedule = new Array(0);
    let rosterFilter = roster.map((p) => p.id);
    let randomized = [];

    while(rosterFilter.length > 0){
        let rndmIdx = GetRandomInt(0, rosterFilter.length);
        let team = rosterFilter[rndmIdx];
        randomized.push(team);
        rosterFilter = rosterFilter.filter(function(value, index, arr) { return value != team; });
    }
    
    // Static options
    let groupA = [28, 3, 10, 14, 12, 32, 6, 20, 13, 19, 29, 24, 9, 16, 17, 21] // rosterFilter.slice(0, rosterFilter.length / 2);
    let groupB = [4, 5, 11, 23, 8, 15, 2, 25, 22, 1, 26, 27, 31, 30, 18, 7] // rosterFilter.slice(rosterFilter.length / 2, rosterFilter.length);
    
    // let groupA = randomized.slice(0, randomized.length / 2);
    // let groupB = randomized.slice(randomized.length / 2, randomized.length);
    let weekOne = schedule.push(new Array(groupA, groupB));

    let i = 1;
    // setup rotating schedule
    while(i < LeagueSettings.totalWeeks){
        let currentWeek = new Array([], []);
        if (i === 1) {
        } else if (i === 19) {   // knock-downs WEEK 20
            groupA = [ 6, 3,29,22,28,11, 2,19,12,27,14, 8,1,32,25,26];
            groupB = [10,21,30,20,15, 9,16,23,18, 4,17,31,5,13,24, 7];
            currentWeek = new Array([...groupA], [...groupB]);
        } else if (i === 9) {   // knock-downs WEEK 10
            groupA = [6,9,20,7,17,30,2,27,31,16,12,4,15,14,23,24] // rosterFilter.slice(0, rosterFilter.length / 2);
            groupB = [3,19,25,13,21,28,22,5,26,10,11,8,1,32,29,18] // rosterFilter.slice(rosterFilter.length / 2, rosterFilter.length);
            currentWeek = new Array([...groupA], [...groupB]);
        } else if (i === 10) {
            groupA = [2,27,31,16,12,4,15,14,23,24,6,9,20,7,17,30]
            groupB = [28,22,5,26,10,11,8,1,32,29,18,3,19,25,13,21]
            currentWeek = new Array([...groupA], [...groupB]);
        } else if (i === 11) {
            groupA = [32,12,6,14,9,28,20,3,24,17,16,13,21,19,29,10]
            groupB = [31,27,30,26,23,25,18,22,11,15,8,7,2,4,5,1]
            currentWeek = new Array([...groupA], [...groupB]);
        } else if (i === 12) {
            groupA = [14,28,24,29,17,9,19,21,13,10,16,3,32,12,20,6]
            groupB = [1,2,5,4,8,11,7,15,18,22,23,25,27,26,30,31]
            currentWeek = new Array([...groupA], [...groupB]);
        } else if (i === 13) {
            groupA = [13,6,20,10,32,17,14,19,28,29,21,16,24,9,3,12]
            groupB = [30,27,31,25,26,23,22,18,15,7,8,11,4,5,2,1]
            currentWeek = new Array([...groupA], [...groupB]);
        } else if (i === 14) {
            groupA = [32,10,9,16,17,28,24,29,3,12,21,20,6,14,13,19]
            groupB = [1,2,4,5,11,8,7,18,15,22,23,27,26,25,31,30]
            currentWeek = new Array([...groupA], [...groupB]);
        } else if (i === 15) {
            groupA = [13,19,29,12,20,28,24,10,32,21,3,17,9,16,14,6]
            groupB = [27,31,30,25,26,23,18,15,22,11,8,5,7,4,2,1]
            currentWeek = new Array([...groupA], [...groupB]);
        } else if (i === 16) {
            groupA = [20,12,17,21,10,16,28,14,9,6,3,32,19,13,24,29]
            groupB = [1,2,4,5,8,7,11,15,18,22,23,25,27,26,30,31]
            currentWeek = new Array([...groupA], [...groupB]);
        } else if (i === 17) {
            groupA = [24,9,29,6,19,10,20,16,12,17,14,3,32,13,28,21]
            groupB = [31,30,27,25,26,23,22,18,15,7,8,11,2,1,5,4]
            currentWeek = new Array([...groupA], [...groupB]);
        } else if (i === 18) {
            groupA = [28,19,3,4,10,12,8,11,17,13,22,29,26,24,27,30]
            groupB = [ 6, 5,1,2,32,21,7,15,18,14,23,20,25,16, 9,31]
            currentWeek = new Array([...groupA], [...groupB]);
        } else {
            groupB = moveLastItemToStart(groupB);
            currentWeek = new Array([...groupA], [...groupB]);
        }
        schedule.push(currentWeek);
        i++;
    }
    // once schedule is set, cycle through each week
    // to rotate the start order
    for(let w=1; w < schedule.length; w++) {
        if (w !== 9 && w !== 19){
            // calc n times
            let cycles = w;
            while(cycles > 0) {
                // shift team group A
                let fItem = schedule[w][0].pop();
                schedule[w][0].unshift(fItem);
                // shift team group B
                fItem = schedule[w][1].pop();
                schedule[w][1].unshift(fItem);
                cycles--;
            }
        }
    }
    setSchedule(schedule);
    return schedule;
    // console.log(schedule);
};

const getSchedule = () => {
    return schedule;
    /*
    let schedule = schedule; // JSON.parse(localStorage.getItem(SCHEDULE));
    // debugger;
    if (!IsNotUndefinedOrNotNull(schedule) || (IsNotUndefinedOrNotNull(version) && version !== '2022-07-09')) {
        // update schedule version
        clearSchedule();
        schedule = createScheduleFromPlayerRoster(roster);
        return schedule;
    } else {
        return schedule;
    }
    */
};

const getTotalRounds = () => {
    return schedule.numRounds;
}

const getScheduleVersion = () => {
    const version = localStorage.getItem(SCHEDULE_VERSION);
    return version;
};

const getScheduleByWeekNum = (weekNum) => {
    return schedule.rounds.filter(function(r) { return r.roundNum == weekNum });
    
    //const schedule = JSON.parse(localStorage.getItem(SCHEDULE));
    //if(weekNum > schedule.length) { /*TODO: return default*/ }
    //return schedule[weekNum];
};

const setSchedule = (schedule) => {
    let dt = new Date("07/08/2022");
    let version = getScheduleVersion();
    if(!IsNotUndefinedOrNotNull(version) || version !== '2022-07-08') {
        localStorage.setItem(SCHEDULE_VERSION, dt.toISOString().slice(0, 10));
    }
    localStorage.setItem(SCHEDULE, JSON.stringify(schedule));
};

const clearSchedule = () => {
    localStorage.removeItem(SCHEDULE);
};

const moveLastItemToStart = (items) => {
    let shifted = [...items];
    let lastItem = shifted.pop();
    shifted.unshift(lastItem);
    return shifted;
};

const ScheduleService = {
    getLeagueSettings,
    createScheduleFromPlayerRoster,
    getTotalRounds,
    getSchedule,
    getScheduleByWeekNum,
    setSchedule,
    clearSchedule
};

export default ScheduleService;