import React from "react";
import {
  classNamesFunction,
  DefaultButton,
  Link,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  styled,
  TextField,
} from "@fluentui/react";
import { get } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import {LogoTitle} from "../../components/layout";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "@reduxjs/toolkit";
import {userSelector} from "../../store/user/userSelectors";
import {userLogin} from "../../services/userApi";
import {loginSuccess} from "../../store/auth/authSlice";

const demoUsers = [
  {
    username: "admin",
    password: "admin",
    roles: ["admin"],
  },
  {
    username: "demo",
    password: "demo",
    roles: ["user"],
  },
];

const getClassNames = classNamesFunction();

const LoginForm = ({ theme, styles }) => {

  const dispatch = useDispatch();
  const {user} = useSelector(userSelector);
  const bac = bindActionCreators({loginSuccess}, dispatch);
  const { handleSubmit, control, formState: { errors } } = useForm();
  const [error, setError] = React.useState();
  const navigate = useNavigate();
  const location = useLocation();

  const from =  location.state?.from || { pathname: "/" };

  const isAuthenticated = user !== null;

  const onSubmit = async (values) => {
    setError(null);
    const {isSuccess, data} =  await userLogin(values, dispatch);
    if(isSuccess) navigate("/dashboard");
  }

  const getErrorMessage = (name) => {
    return get(errors, name + ".message");
  }

  const classNames = getClassNames(styles, { theme });
  return (
      <section className="bg-home bg-circle-gradiant d-flex align-items-center">
        <div className="bg-overlay bg-overlay-white"/>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="form-signin p-4 bg-white rounded shadow">
                  <Stack>
                    {isAuthenticated && (
                        <Stack tokens={{ childrenGap: "1em" }}>
                          <h3 className={classNames.title}>
                            {user.username}, you are already signed in.
                          </h3>
                          <Stack horizontal tokens={{ childrenGap: "1em" }}>
                            <PrimaryButton
                                onClick={() => navigate("/")}
                                iconProps={{ iconName: "Home" }} >
                              Go to Home
                            </PrimaryButton>
                            <DefaultButton onClick={e=>{}} iconProps={{ iconName: "SignOut" }}>
                              Logout
                            </DefaultButton>
                          </Stack>
                        </Stack>
                    )}

                    {
                        !isAuthenticated && (
                            <form onSubmit={handleSubmit(onSubmit)} data-testid="form-node">
                              <Stack horizontal
                                     horizontalAlign="space-between"
                                     verticalAlign="center">
                                <LogoTitle/>
                              </Stack>
                              <h3 className={classNames.title}>Login</h3>
                              <Stack tokens={{ childrenGap: "1em" }}>
                                <Controller
                                    as={TextField}
                                    control={control}
                                    autoComplete="email"
                                    autoFocus
                                    minLength={3}
                                    defaultValue=""
                                    name="email"
                                    rules={{
                                      required: "Please enter your email",
                                      minLength: {
                                        value: 3,
                                        message: "Please enter your email",
                                      },
                                      maxLength: { value: 32, message: "Username is too long" },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Email"
                                            required
                                            inputClassName={"form-control"}
                                            errorMessage={getErrorMessage("email")}
                                        />
                                    )}
                                />

                                <Controller
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                    minLength={4}
                                    maxLength={64}
                                    rules={{
                                      required: "Please enter your password",
                                      minLength: {
                                        value: 4,
                                        message: "Please enter your password",
                                      },
                                      maxLength: { value: 64, message: "Password is too long" },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Password"
                                            type="password"
                                            required
                                            autoComplete="current-password"
                                            errorMessage={getErrorMessage("password")}
                                        />
                                    )}
                                />

                                <Stack
                                    horizontal
                                    horizontalAlign="end"
                                    tokens={{ childrenGap: "1em" }} >
                                  <Link>Find my password</Link>
                                  <PrimaryButton type="submit" className="btn btn-primary" data-testid="login-button">Login</PrimaryButton>
                                </Stack>
                                <Stack>
                                  <h3>Demo users</h3>
                                  <ul className="mb-0">
                                    <li>nobody</li>
                                  </ul>
                                </Stack>
                                {
                                    error && (
                                        <MessageBar
                                            messageBarType={MessageBarType.error}
                                            onDismiss={() => setError(null)} >
                                          {error}
                                        </MessageBar>)
                                }
                              </Stack>
                            </form>
                        )}
                  </Stack>
              </div>
            </div>

          </div>
        </div>
      </section>
  );
}

function getStyles({ theme }) {
  return {
    root: {
      margin: "10em auto",
      width: "30em",
      backgroundColor: theme.palette.neutralLighter,
      padding: theme.spacing.l2,
      borderRadius: theme.effects.roundedCorner2,
      boxShadow: theme.effects.elevation4
    },
    title: {
      ...theme.fonts.xLargePlus,
      marginTop:theme.spacing.l1,
    },
  };
}

export default styled(LoginForm, getStyles);
