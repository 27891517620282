import React, {useLayoutEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { styled } from "@fluentui/react";
import './utilities/StringPrototypes';
import {MasterLayout, ProtectedRoutes} from "./components/layout";
import PublicRoutes from "./components/layout/PublicRoutes";
import {Login, Signup} from "./pages/Auth";
import PurchaseOrder from "./pages/PurchaseOrder";
import Equipment from "./pages/Equipment";
import Schedule from "./pages/Schedule";
import Roster from "./pages/Roster";
import Results from "./pages/Results";
import Standings from "./pages/Standings";
import {NoMatch} from "./components/route";


const App = ({ theme })=>{

  const { semanticColors } = theme;
  useLayoutEffect(() => {
    document.body.style.backgroundColor = semanticColors.bodyBackground;
    document.body.style.color = semanticColors.bodyText;
  }, [semanticColors]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MasterLayout />}>
          <Route path="schedule" element={<Schedule/>}/>
          <Route path="roster" element={<Roster/>}/>
          <Route path="results" element={<Results/>}/>
          <Route path="standings" element={<Standings/>}/>
          <Route path="/teams" >
            <Route path="schedule" element={<Schedule/>}/>
            <Route path="roster" element={<Roster/>}/>
            <Route path="results" element={<Results/>}/>
            <Route path="standings" element={<Standings/>}/>
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default styled(App);


/*
<Route path="/" element={<ProtectedRoutes/>}>
</Route>
<Route path="login" element={<PublicRoutes/>}>
  <Route path="/login" element={<Login/>}/>
</Route>
<Route path="signup" element={<PublicRoutes/>}>
  <Route path="/signup" element={<Signup/>}/>
</Route>
 */
