import { createTheme } from '@fluentui/react';
import { addVariants } from '@fluentui/scheme-utilities';
//https://github.com/microsoft/fluentui/tree/master/packages/theme-samples
import { DarkTheme, TeamsTheme } from "@fluentui/theme-samples";

const palette = {
    //...TeamsTheme.palette,
    themePrimary:'#0078d4',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
}
const semanticColors = {
    buttonBackground: 'transparent',
    buttonBackgroundHovered: '#bdbdbd',
    buttonBackgroundPressed: '#a7a7a7',

    buttonText: '#252424',
    buttonTextPressed: '#252424',
    buttonTextHovered: '#252424',

    buttonBorder: '#bdbdbd',
    menuItemText: '#292827'
}

const components = {
    Card: {
        styles: {
            root: {
                background: palette.neutralLighter,
            },
        },
    }
}

export const AppTheme = createTheme({
    palette,
    semanticColors,
    components
});

addVariants(AppTheme);



export const themes = {
    default: AppTheme,
    dark: DarkTheme,
};
