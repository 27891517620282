import React, {useEffect, useState} from 'react';
import round1Results from './round1_results.json';
import round2Results from './round2_results.json';
import round3Results from './round3_results.json';
import round4Results from './round4_results.json';
import round5Results from './round5_results.json';
import round6Results from './round6_results.json';
import round7Results from './round7_results.json';
import round8Results from './round8_results.json';
import round9Results from './round9_results.json';
import round10Results from './round10_results.json';
import round11Results from './round11_results.json';
import round12Results from './round12_results.json';
import round13Results from './round13_results.json';
import round14Results from './round14_results.json';
import round15Results from './round15_results.json';
import round16Results from './round16_results.json';
import round17Results from './round17_results.json';
import round18Results from './round18_results.json';
import round19Results from './round19_results.json';
import round20Results from './round20_results.json';
import ScheduleService from 'services/scheduleService';
import { GroupedList, IGroup } from '@fluentui/react/lib/GroupedList';
import { IColumn, DetailsRow } from '@fluentui/react/lib/DetailsList';
import { getFormattedTeeTime, getSimpleDate, incrementStartTime, IsNotUndefinedOrNotNull } from 'utilities/utils';

import {
  DetailsHeader,
  DetailsList,
  ConstrainMode,
  DetailsListLayoutMode,
  SelectionMode,
  mergeStyleSets,
  TooltipHost,
  CheckboxVisibility,
  Dropdown,
  DropdownMenuItemType
} from '@fluentui/react';

const columns = [
  {
    key: 'teamData',
    name: 'Team',
    fieldName: 'teamData',
    minWidth: 185,
    maxWidth: 185,
  },
  {
    key: 'roundGrossNet',
    name: 'Gross/Net',
    fieldName: 'roundGrossNet',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'roundStats',
    name: 'Pars / Birds / Eagles',
    fieldName: 'roundStats',
    minWidth: 135,
    maxWidth: 135,
  },
  {
    key: 'roundAttendPoints',
    name: 'Just Showing Up Pts',
    fieldName: 'roundAttendPoints',
    minWidth: 150,
    maxWidth: 150,
  },
  {
    key: 'roundHolePoints',
    name: 'Hole Pts',
    fieldName: 'roundHolePoints',
    minWidth: 60,
    maxWidth: 60,
  },
  {
    key: 'roundNetPoints',
    name: 'Net Pts',
    fieldName: 'roundNetPoints',
    minWidth: 60,
    maxWidth: 60,
  },
  {
    key: 'roundTotalPoints',
    name: 'Total',
    fieldName: 'roundTotalPoints',
    minWidth: 60,
    maxWidth: 60,
  }
];

const groups = [
  { key: 'match0', name: 'Matchup 01', startIndex: 0, count: 2, level: 0 },
  { key: 'match1', name: 'Matchup 02', startIndex: 2, count: 2, level: 0 },
  { key: 'match2', name: 'Matchup 03', startIndex: 4, count: 2, level: 0 },
  { key: 'match3', name: 'Matchup 04', startIndex: 6, count: 2, level: 0 },
  { key: 'match4', name: 'Matchup 05', startIndex: 8, count: 2, level: 0 },
  { key: 'match5', name: 'Matchup 06', startIndex: 10, count: 2, level: 0 },
  { key: 'match6', name: 'Matchup 07', startIndex: 12, count: 2, level: 0 },
  { key: 'match7', name: 'Matchup 08', startIndex: 14, count: 2, level: 0 },
  { key: 'match8', name: 'Matchup 09', startIndex: 16, count: 2, level: 0 },
  { key: 'match9', name: 'Matchup 10', startIndex: 18, count: 2, level: 0 },
  { key: 'match10', name: 'Matchup 11', startIndex: 20, count: 2, level: 0 },
  { key: 'match11', name: 'Matchup 12', startIndex: 22, count: 2, level: 0 },
  { key: 'match12', name: 'Matchup 13', startIndex: 24, count: 2, level: 0 },
  { key: 'match13', name: 'Matchup 14', startIndex: 26, count: 2, level: 0 },
  { key: 'match14', name: 'Matchup 15', startIndex: 28, count: 2, level: 0 },
  { key: 'match15', name: 'Matchup 16', startIndex: 30, count: 2, level: 0 }
  // { key: 'match16', name: 'Matchup 17', startIndex: 32, count: 2, level: 0 },
  // { key: 'match17', name: 'Matchup 18', startIndex: 34, count: 2, level: 0 }
];

const gridStyles = {
  root: {
    selectors: {
      '& [role=grid]': {
        height: '100vh',
      },
    },
  },
};

const dropdownStyles = {
  dropdown: {
    width: 200,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
};

const classNames = mergeStyleSets({
  header: {
    margin: '.5em 0',
  },
});

const setupSelectionDropdown = () => {
  let settings = ScheduleService.getLeagueSettings();
  let options = [];
  for (let w=0; w < settings.totalWeeks; w++) {
    let wknum = w + 1;
    if (w === 0) {
      options.push({ key: 'firstHalfHeaader', text: 'First Half', itemType: DropdownMenuItemType.Header });
      let text = 'Week ' +wknum;
      options.push({ key: w, text: text });
    } else if (w === 15) {
      let text = 'Week ' + wknum + ' (CANCELLED)';
      options.push({ key: w, text: text });
    } else if (w === 9) {
      let text = 'Week ' + wknum + ' (Knock-Downs)';
      options.push({ key: w, text: text });
      options.push({ key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider });
      options.push({ key: 'secondHalfHeaader', text: 'Second Half', itemType: DropdownMenuItemType.Header });
    } else if (w === 19) {
      let text = 'Week ' + wknum + ' (Knock-Downs)';
      options.push({ key: w, text: text });
    } else {
      let text = 'Week ' +wknum;
      options.push({ key: w, text: text });
    }
  }
  return options;
}

function Results(props) {
  const [weekly, setWeekly]  = useState([]);
  const [selectedKey, setSelectedKey]  = useState(18);
  const [options, setOptions]  = useState([]);

  useEffect(()=>{
    const options = setupSelectionDropdown();
    setOptions(options);
  },[]);

  useEffect(()=>{
    setupWeeklyResults(selectedKey);
  },[selectedKey])

  const onWeekSelectionChange = (event, option, index)=> {
    setSelectedKey(option.key);
    setWeekly([]);
  }

  const setupWeeklyResults = (weekNum) => {
    const results = [];
    let roundResults = [];

    if (weekNum === 0) {
      roundResults = round1Results;
    } else if (weekNum === 1) {
       roundResults = round2Results;
    } else if (weekNum === 2) {
       roundResults = round3Results;
    } else if (weekNum === 3) {
      roundResults = round4Results;
    } else if (weekNum === 4) {
       roundResults = round5Results;
    } else if (weekNum === 5) {
       roundResults = round6Results;
    } else if (weekNum === 6) {
      roundResults = round7Results;
    } else if (weekNum === 7) {
      roundResults = round8Results;
    } else if (weekNum === 8) {
      roundResults = round9Results;
    } else if (weekNum === 9) {
      roundResults = round10Results;
    } else if (weekNum === 10) {
      roundResults = round11Results;
    } else if (weekNum === 11) {
      roundResults = round12Results;
    } else if (weekNum === 12) {
      roundResults = round13Results;
    } else if (weekNum === 13) {
      roundResults = round14Results;
    } else if (weekNum === 14) {
      roundResults = round15Results;
    } else if (weekNum === 15) {
     roundResults = round16Results;
    } else if (weekNum === 16) {
     roundResults = round17Results;
    } else if (weekNum === 17) {
      roundResults = round18Results;
    } else if (weekNum === 18) {
      roundResults = round19Results;
    } //else if (weekNum === 19) {
    //   roundResults = round20Results;
    // } else {
    //   roundResults = [];
    // }

    for (let i = 0; i < roundResults.length; i++) {
      roundResults[i].teamData = `T${roundResults[i].teamName}: ` + `${roundResults[i].playerName}` + ` HCP (${roundResults[i].handicapAtRound})`;
      roundResults[i].roundGrossNet = `${roundResults[i].roundGross} / ` +`${roundResults[i].roundNet}`;
      roundResults[i].roundStats = `${roundResults[i].roundPars} / ` +`${roundResults[i].roundBirds} / ` +`${roundResults[i].roundEagles}`;

      results.push(roundResults[i]);
    }

    setWeekly(results);
    console.log('Current week standings: ', results);
  }
  
  const onRenderDetailsHeader = (props, defaultRender) => {
    if (!props) {
      return null;
    }
    const onRenderColumnHeaderTooltip = tooltipHostProps => (
      <TooltipHost {...tooltipHostProps} />
    );

    return defaultRender({
      ...props,
      onRenderColumnHeaderTooltip,
    });
  };

  return (
    <div style={{overflow:"auto"}}>
      <h2 className={classNames.header}>Round Results</h2>
      <div style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
        <Dropdown
            placeholder="Select a week"
            label="Select a week"
            options={options}
            styles={dropdownStyles}
            defaultSelectedKey={selectedKey}
            onChange={(e,o,i)=>onWeekSelectionChange(e,o,i)}
        />
      </div>
      <DetailsList
        styles={gridStyles}
        items={weekly.slice(0, 100)}
        columns={columns}
        groups={groups}
        checkboxVisibility={CheckboxVisibility.always}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        constrainMode={ConstrainMode.unconstrained}
        selectionMode={SelectionMode.multiple}
        onRenderDetailsHeader={onRenderDetailsHeader}
        selectionPreservedOnEmptyClick
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
      />{' '}
    </div>
  );
}

export default Results;