import React from "react";
import {
    classNamesFunction,
    DefaultButton,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    styled,
    TextField,
} from "@fluentui/react";
import { get } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {LogoTitle} from "../../components/layout";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "@reduxjs/toolkit";
import {userSelector} from "../../store/user/userSelectors";
import {loginSuccess} from "../../store/auth/authSlice";
import {signupUserSchema} from "../../Validations/SignupValidation";
import {userSignup} from "../../services/userApi";

const getClassNames = classNamesFunction();

const SignUpForm = ({ theme, styles }) => {

    const dispatch = useDispatch();
    const {user} = useSelector(userSelector);
    const bac = bindActionCreators({loginSuccess}, dispatch);
    const { handleSubmit, control, formState: { errors } } = useForm({
        resolver:yupResolver(signupUserSchema),
    });
    const [error, setError] = React.useState();
    const navigate = useNavigate();
    const location = useLocation();

    const from =  location.state?.from || { pathname: "/" };

    console.log(errors);

    const onSubmit = async (values) => {
        setError(null);
        const {isSuccess, data} =  await userSignup(values, dispatch);
        if(isSuccess) navigate("/login");
    }

    const getErrorMessage = (name) => {
        return get(errors, `${name}.message`);
    }

    const classNames = getClassNames(styles, { theme });
    return (
        <section className="bg-home bg-circle-gradiant d-flex align-items-center">
            <div className="bg-overlay bg-overlay-white"/>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="form-signin p-4 bg-white rounded shadow">
                            <Stack>
                                <form onSubmit={handleSubmit(onSubmit)} data-testid="form-node" noValidate>
                                    <Stack horizontal
                                           horizontalAlign="space-between"
                                           verticalAlign="center">
                                        <LogoTitle/>
                                    </Stack>
                                    <h3 className={classNames.title}>Signup</h3>
                                    <Stack tokens={{ childrenGap: "1em" }}>
                                        <Controller
                                             as={TextField}
                                            control={control}
                                            autoComplete="first-name"
                                            autoFocus
                                            minLength={3}
                                            defaultValue=""
                                            name="firstName"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="First Name"
                                                    required
                                                    inputClassName={"form-control"}
                                                    errorMessage={getErrorMessage("firstName")}
                                                />
                                            )}
                                        />
                                        <Controller
                                            as={TextField}
                                            control={control}
                                            autoComplete="last-name"
                                            autoFocus
                                            minLength={3}
                                            defaultValue=""
                                            name="lastName"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Last Name"
                                                    required
                                                    inputClassName={"form-control"}
                                                    errorMessage={getErrorMessage("lastName")}
                                                />
                                            )}
                                        />
                                        <Controller
                                            as={TextField}
                                            control={control}
                                            autoComplete="phone-number"
                                            autoFocus
                                            minLength={3}
                                            defaultValue=""
                                            name="phoneNumber"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Phone Number"
                                                    required
                                                    inputClassName={"form-control"}
                                                    errorMessage={getErrorMessage("phoneNumber")}
                                                />
                                            )}
                                        />
                                        <Controller
                                            as={TextField}
                                            control={control}
                                            autoComplete="user-name"
                                            autoFocus
                                            minLength={3}
                                            defaultValue=""
                                            name="userName"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Username"
                                                    required
                                                    inputClassName={"form-control"}
                                                    errorMessage={getErrorMessage("userName")}
                                                />
                                            )}
                                        />
                                        <Controller
                                            as={TextField}
                                            control={control}
                                            autoComplete="email"
                                            autoFocus
                                            minLength={3}
                                            defaultValue=""
                                            name="email"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Email"
                                                    required
                                                    inputClassName={"form-control"}
                                                    errorMessage={getErrorMessage("email")}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="password"
                                            control={control}
                                            defaultValue=""
                                            minLength={4}
                                            maxLength={64}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Password"
                                                    type="password"
                                                    required
                                                    autoComplete="current-password"
                                                    errorMessage={getErrorMessage("password")}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="confirmPassword"
                                            control={control}
                                            defaultValue=""
                                            minLength={4}
                                            maxLength={64}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Confirm Password"
                                                    type="password"
                                                    required
                                                    errorMessage={getErrorMessage("confirmPassword")}
                                                />
                                            )}
                                        />

                                        <Stack horizontalAlign="center"
                                            tokens={{ childrenGap: "1em" }} >
                                            <Link to="/login">Already have an account ? Sign in</Link>
                                            <PrimaryButton type="submit" className="btn btn-primary" data-testid="login-button">Signup</PrimaryButton>
                                        </Stack>
                                        {
                                            error && (
                                                <MessageBar
                                                    messageBarType={MessageBarType.error}
                                                    onDismiss={() => setError(null)} >
                                                    {error}
                                                </MessageBar>)
                                        }
                                    </Stack>
                                </form>
                            </Stack>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

function getStyles({ theme }) {
    return {
        root: {
            margin: "10em auto",
            width: "30em",
            backgroundColor: theme.palette.neutralLighter,
            padding: theme.spacing.l2,
            borderRadius: theme.effects.roundedCorner2,
            boxShadow: theme.effects.elevation4
        },
        title: {
            ...theme.fonts.xLargePlus,
            marginTop:theme.spacing.l1,
        },
    };
}

export default styled(SignUpForm, getStyles);
